import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => {

	return {
		container: {
			background   : theme.other.darkMode ? theme.other.paletteNew.darkModeInclinedBlack2 : theme.other.paletteNew.secondaryBlack25,
			aspectRatio  : props?.cardType === "reel" ? "9/16" : "16 / 9",
			borderRadius : "1.5rem",
			display      : "grid",
			placeItems   : "center",
			boxSizing    : "content-box",

			[rooterMediaQuery.mobile]: {
				borderRadius : "unset",
				borderBottom : `5px solid ${ theme.other.paletteNew.secondaryDarkGrey }`
			}
		},
		title: {
			fontSize   : "1.35rem",
			fontWeight : "500"
		}

	};
}, { name: "removePostView" } );
