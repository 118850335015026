import { useClickOutside } from "@mantine/hooks";
import dayjs               from "dayjs";
import fromNow             from "dayjs/plugin/relativeTime";
import utc                 from "dayjs/plugin/utc";
import { capitalize }      from "lodash";
import dynamic             from "next/dynamic";
import { useState }        from "react";

import { getBaseURL, shortenNumber } from "_common/utils";
import Icon                          from "_components/atoms/icon";
import Image                         from "_components/atoms/image";
import Typography                    from "_components/atoms/typography";

import useStyles from "./styles";

dayjs.extend ( utc );
dayjs.extend ( fromNow );

const ReportFollowModal = dynamic ( () => import ( "_components/molecules/reportFollowModal" ), { ssr: false } );

const component = ( {
	data,
	activeTab,
	onClickMenuOption,
	isAnimationStarted,
	isLoggedIn,
	watchNowClickHandler,
	hideMenuOptions,
	getShareLink,
	cardType,
	customClasses = {}
} ) => {

	const [ isThreeDotMenuOpen, setIsThreeDotMenuOpen ] = useState ( false );

	const { classes } = useStyles ( { isThreeDotMenuOpen }, { name: "bottomDetailsSection" } );

	const ref = useClickOutside ( () => setIsThreeDotMenuOpen ( false ) );

	const createdTS = data.createdAt ? dayjs ( dayjs.utc ( data.createdAt ) ).fromNow () : null;

	const {
		feedType,
		userData
	} = data;

	const onClickThreeDot = () => {
		setIsThreeDotMenuOpen ( true );
	};

	return (
		<div className = { classes.contentContainer }>

			<div className = "flex">
				{cardType === "reel" ? null : (
					<a
						href   = { !data?.sponsoredAd ? `/profile/${ userData?.id }` : null }
						rel    = "noreferrer"
						target = "_blank"
					>
						<Image
							className   = { classes.profileImage }
							placeholder = "/images/circle-placeholder.webp"
							src         = { userData?.photo }
							type        = "ldpi"
						/>
					</a>
				)}

				<div className = { classes.detailsContainer }>

					<div className = { classes.titleContainer }>
						<Typography
							className = { `${ classes.title } ${ customClasses.bottomDetailsTitle }` }
							onClick   = { watchNowClickHandler }
							title     = { data.title }
						/>

						{
							isLoggedIn && !hideMenuOptions ? (
								<div
									ref       = { ref }
									className = { "threeDotIcon" }
									onClick   = { onClickThreeDot }
								>
									<ReportFollowModal
										activeTab         = { activeTab } // need to find some other prop
										feedId            = { data.id }
										feedType          = { feedType }
										from              = { "videoCard" }
										getSrc            = { () => getBaseURL () + getShareLink ( { currentTime: 0 } ) }
										isFollowing       = { userData?.isFollowing }
										onClickShareModal = { () => onClickMenuOption ( { type: "share" } ) }
										updateDeletedPost = { () => onClickMenuOption ( { type: "delete", data } ) }
										updateFeedList    = { ( { type } ) => onClickMenuOption ( { type, data } ) }
										userId            = { userData?.id }
										userName          = { userData?.name }
									/>
								</div>
							) : null
						}
					</div>

					<a
						href   = { `/profile/${ userData?.id }` }
						rel    = "noreferrer"
						style  = { {
							textDecoration: "none"
						} }
						target = "_blank"
					>

						<div className = { classes.nameContainer }>
							<Typography
								bold
								className = { classes.name }
								color     = "secondary"
								size      = "sm"
								title     = { userData.name }
							/>

							{userData.isCeleb ? (
								<Icon sameInBothTheme
									className = { classes.verifyIcon }
									name      = "verifiedTickGrey"
								/>
							) : null}
						</div>

					</a>

					<div className = { classes.streamInfo }>
						{hideMenuOptions ? (
							<Typography
								bold
								color = "secondary"
								size  = "sm"
								title = { `${ capitalize ( cardType ) } is not posted yet` }
							/>
						) : (
							<Typography
								bold
								color = "secondary"
								size  = "sm"
								title = { createdTS }
							/>
						)}

						{( data?.views && !( cardType === "reel" ) ) ? (
							<>
								<span className = { classes.ellipse }></span>

								<Typography
									bold
									color = "secondary"
									size  = "sm"
									title = { `${ shortenNumber ( data.views ) } ${ data?.isLive ? "watching" : "Views" }` }
								/>
							</>
						) : null}

					</div>
				</div>
			</div>
		</div>
	);
};

export default component;
