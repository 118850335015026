import { Chip as MChip } from "@mantine/core";

function component ( props ) {
	const { children, ...rest } = props;

	return (
		<MChip { ...rest }>
			{children}
		</MChip>
	);
}

component.Group = MChip.Group;

export default component;