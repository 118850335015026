import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props ) => {
	return {
		liveTag: {
			background: `${ theme.other.paletteNew.tertiaryPink } !important`
		},
		tagsContainer: {
			position   : "absolute",
			// bottom     : "3.55%",
			// left       : "2%",
			bottom     : props?.feedType === "REEL" ? "1.125%" : "4%",
			left       : props?.feedType === "REEL" ? "2%" : "2.25%",
			display    : "flex",
			alignItems : "center"
		},
		viewCount: {
			marginLeft: props?.isLive ? "1.25rem" : ""
		}

	};
} );

