import React from "react";

import Presentation from "./presentation";

const component = props => {
	return (
		<Presentation
			{ ...props }
		/>
	);
};

component.Group = Presentation.Group;
export default component;