import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props ) => {
	return {
		container: {
			padding      : "1.6rem",
			borderBottom : props?.sponsoredAd ? `5px solid ${ theme.other.colors.border.primary }` : null,
			cursor       : props?.sponsoredAd ? "pointer" : null
		},

		nameAndSectionContainer: {
			display: "flex"
		},

		profileImage: {
			height       : "4rem",
			width        : "4rem",
			borderRadius : "100%"
			// marginRight  : "1.2rem"
		},
		profileIconAnchor: {
			display        : "flex",
			alignItems     : "center",
			justifyContent : "center",
			cursor         : "pointer"
		},
		title: {
			display         : "-webkit-box",
			WebkitLineClamp : "2",
			WebkitBoxOrient : "vertical",
			overflow        : "hidden",
			fontSize        : "1.6rem",
			wordBreak       : "break-word"
		},

		verifyIcon: {
			height     : "1.5rem",
			width      : "1.5rem",
			marginLeft : "0.25rem"
		},

		nameContainer: {
			display    : "flex",
			alignItems : "center",
			width      : "100%"
		},

		name: {
			fontSize        : "1.6rem",
			maxWidth        : "85%",
			WebkitBoxOrient : "vertical",
			WebkitLineClamp : "1",
			fontWeight      : "500",
			lineHeight      : "1.2"
		},

		ellipse: {
			width           : "2.5px",
			height          : "2.5px",
			margin          : "0.75rem",
			borderRadius    : "50%",
			backgroundColor : "#757575"
		},
		streamInfo: {
			display    : "flex",
			alignItems : "center",
			fontSize   : "1.4rem"
		},
		nameAndInfoContainer: {
			width         : "100%",
			display       : "flex",
			flexDirection : "column",
			margin        : "1rem 1.2rem"
		},

		actionBtnsContainer: {
			padding        : "0 1.6rem",
			paddingBottom  : "1.6rem",
			display        : "flex",
			justifyContent : "space-between",
			borderBottom   : `5px solid ${ theme.other.colors.border.primary }`
		},

		actionIcon: {
			height      : "2rem",
			width       : "2rem",
			marginRight : "0.6rem"
		},

		actionButtonText: {
			fontSize: "1.4rem"
		},

		actionBtnContainer: {
					  // marginRight : "1rem",
			display    : "flex",
			alignItems : "center"
		},
		mainActionBtns: {
			display : "flex",
			gap     : "3rem"
		},
		divider: {
			height     : "5px",
			background : theme.other.colors.border.primary
		},

		menuIcon: {
			height : "2rem",
			width  : "2rem"
		}

	};
}, { name: "bottomDetailsSection" } );

