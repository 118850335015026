import { useRouter }                        from "next/router";
import { useCallback, useEffect, useState } from "react";

import { storeEvent }                                     from "_analytics/index";
import { useGetLoggedIn }                                 from "_common/hooks/auth";
import { useGetPipModeStatus }                            from "_common/hooks/player";
import { useGetInProcessingItem, useSetInProcessingItem } from "_common/hooks/uploadVideo";
import toast                                              from "_common/toast";
import { LIVESESSION, REEL, VIDEO, isPageRoute }          from "_common/utils";
import { useRTCWebSocketContext }                         from "_components/features/rtcSocket";
import { STREAM_TIER }                                    from "_components/pages/proPage/constants";
import { useSetCanWatchMoreProContent, useSetFinishedAd } from "_jotai/rooterPro";
import { checkInProcessingUpload }                        from "_services/api/upload";

import PostRemovedView from "../postRemovedView";

import Presentation from "./presentation";

const VideoCard = ( {
	activeTab,
	data,
	leftMostCard,
	// onClickMenuOption,
	rightMostCard,
	isWithoutAnimation = false,
	hideMenuOptions,
	fromRecommendations,
	customClasses,
	isMobile = false,
	cardType
} ) => {

	const [ isReported, setIsReported ]       = useState ( false );
	const [ isDeleted, setIsDeleted ]         = useState ( false );
	const [ loading, setLoading ]             = useState ( false );
	const [ refreshedData, setRefreshedData ] = useState ( false );
	const setCanWatchMoreProContent           = useSetCanWatchMoreProContent ();
	const setFinishedAd                       = useSetFinishedAd ();
	const inProcessingItemServer              = data.uploadStatus;
	const inProcessingItemClient              = useGetInProcessingItem ();
	const inProcessingItem                    = refreshedData ? inProcessingItemClient : inProcessingItemServer;
	const { sendMessage }                     = useRTCWebSocketContext ();

	useEffect ( () => {
		if ( refreshedData ) {
			setInProcessingItem ( inProcessingItem );
		}
	}, [ refreshedData ] );

	const setInProcessingItem = useSetInProcessingItem ();

	const getInProcessingStatus = async () => {
		try {
			setLoading ( true );
			if ( activeTab === "reels" ) {
				const res = await checkInProcessingUpload ( { contentType: "video" } );

				if ( res )
					setInProcessingItem ( p => {
						return {
							video : p?.video,
							reel  : {
								type        : "reel",
								feedId      : res.feedId,
								status      : res.status,
								userMessage : res.userMessage
							}
						};
					} );
			}
			if ( activeTab === "videos" ) {
				const res = await checkInProcessingUpload ( { contentType: "video" } );

				if ( res )
					setInProcessingItem ( p => {
						return {
							reel  : p?.reel,
							video : {
								type        : "video",
								feedId      : res.feedId,
								status      : res.status,
								userMessage : res.userMessage
							}
						};
					} );
			}
			setLoading ( false );
		} catch ( error ) {
			setLoading ( false );
			toast.error ( error.displayMsg || "Something Went Wrong!" );
		}
	};

	const isLoggedIn = useGetLoggedIn ();
	const isPIPOpen  = useGetPipModeStatus ();

	const router = useRouter ();

	const getShareLink = useCallback ( ( { currentTime = 0 } ) => {
		if ( data.feedType === REEL || cardType === "reel" ) {
			if ( checkPageRoute ( "profile" ) ) {
				return `/reels/${ data.id }?user=${ router.query.id }`;
			}

			return `/reels/${ data.id }`;
		}
		if ( data.feedType === VIDEO ) {
			if ( currentTime === 0 || !currentTime )
				return `/video/${ data.id }`;

			return `/video/${ data.id }?currentTime=${ currentTime }`;
		}
		if ( data.feedType === LIVESESSION ) {
			return `/stream/${ data.id }`;
		}

		return "";
	}, [] );

	const handleGAEvent = () => {
		if ( !data.feedType )
			return;

		if ( data.feedType === VIDEO ) {
			storeEvent ( VIDEO, "Played", returnPageRoute () );
		}

		if ( data.feedType === LIVESESSION )
			storeEvent ( "Broadcast", "Viewed", returnPageRoute () );

	};

	const onAdFinish = () => {
		const socketEvent = {
			event : "lsAdView",
			data  : {
				sessionId: data?.id
			}
		};

		sendMessage ( socketEvent );
		setCanWatchMoreProContent ( true );
		setFinishedAd ( true );
		handleGAEvent ();
		router.push ( getShareLink ( { currentTime: 0 } ) );
	};

	const watchNowClickHandler = ( { currentTime } ) => {
		const streamTier  = data.streamTier?.toString ();
		const isProStream =  streamTier === STREAM_TIER.PRO_STREAM || streamTier === STREAM_TIER.MID_STREAM;
		/* if ( isProStream ) {
			const node = document.getElementById ( `pro-bar-${ data?.id }` );

			node.click ();

			return;
		} */

		handleGAEvent ();
		router.push ( getShareLink ( { currentTime } ) );
	};

	const checkPageRoute = page => {
		return isPageRoute ( { router, page } );
	};

	const returnPageRoute = () => {
		if ( checkPageRoute ( "live" ) )
			return "live_tab";

		if ( checkPageRoute ( "stream" ) )
			return "stream";

		if ( checkPageRoute ( "game" ) )
			return "game_card";

		if ( checkPageRoute ( "home" ) )
			return "home";

		return null;
	};

	const onClickMenuOption = ( { type, data } ) => {
		if ( type === "report" )
			setIsReported ( true );
		if ( type === "delete" )
			setIsDeleted ( true );

	};

	if ( isReported || isDeleted ) {
		return (
			<PostRemovedView
				cardType = { cardType }
				title    = { `Post ${ isReported ? "Reported" : "Deleted" } Successfully` }
			/>
		);
	}

	return (
		<Presentation
			activeTab            = { activeTab }
			cardType             = { cardType }
			customClasses        = { customClasses }
			data                 = { data }
			fromRecommendations  = { fromRecommendations }
			getShareLink         = { getShareLink }
			hideMenuOptions      = { hideMenuOptions }
			inProcessingItem     = { inProcessingItem }
			isHomePage           = { isPageRoute ( { router, page: "home" } ) }
			isLoggedIn           = { isLoggedIn }
			isMobile             = { isMobile }
			isWithoutAnimation   = { isWithoutAnimation }
			leftMostCard         = { leftMostCard }
			noAnimation          = { isWithoutAnimation || isPIPOpen }
			onAdFinish           = { onAdFinish }
			onClickMenuOption    = { onClickMenuOption }
			onRefresh            = { { fn: getInProcessingStatus, loading: loading } }
			rightMostCard        = { rightMostCard }
			setRefreshedData     = { setRefreshedData }
			watchNowClickHandler = { watchNowClickHandler }
		/>
	);
};

export default VideoCard;
