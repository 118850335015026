import { formattedVideoDuration, shortenNumber, VIDEO } from "_common/utils";

import LiveTag            from "../liveTag";
import VideoDurationCount from "../videoDutaionCount";

import useStyles from "./styles";

const component = ( { data } ) => {
	const { isLive, feedType } = data;

	const { classes } = useStyles ( { isLive, feedType }, { name: "videoCardTags" } );

	if ( data.videoDuration <= 60 ) { // considering it as reel
		return (
			<div className = { classes.tagsContainer }>

				<VideoDurationCount
					isReelCard = { true }
					viewCount  = { shortenNumber ( data.views ) }
				/>
			</div>
		);
	} else if ( isLive ) {
		return (
			<div className = { classes.tagsContainer }>
				<LiveTag
					containerClass = { classes.liveTag }
				/>

				{/* <ViewCount
					isLive    = { isLive }
					viewCount = { data.views }
				/> */}

			</div>
		);
	} else if ( data.feedType === "broadcast" ) {
		return (
			<div className = { classes.tagsContainer }>
				{isLive && (
					<LiveTag
						containerClass = { classes.liveTag }
					/>
				)}

				{/* <ViewCount
					isLive    = { isLive }
					viewCount = { data.views }
				/> */}

			</div>
		);
	} else if ( data.feedType === VIDEO || data.feedType === "videos" ) {
		return (
			<div className = { classes.tagsContainer }>
				<VideoDurationCount
					viewCount = { formattedVideoDuration ( data.videoDuration ) }
				/>
			</div>
		);
	}

	return null;

};

export default component;