import { shortenNumber } from "_common/utils";
import Icon              from "_components/atoms/icon";
import Typography        from "_components/atoms/typography";

import useStyles from "./styles";

export default function VideoDurationCount ( { viewCount, containerClass = "", isReelCard } ) {
	const { classes } = useStyles ( undefined, { name: "video-duration-count" } );

	return (
		<div className = { `${ classes.viewTagContainer } ${ containerClass }` }>

			{
				isReelCard ? (
					<Icon
						sameInBothTheme
						className = { classes.reelPlayeIcon }
						name      = "icReelViews"
						size      = { 10 }
					/>
				) : null
			}

			<Typography className = { `${ classes.viewTagText }` }
				title                = { shortenNumber ( viewCount ) }
			/>
		</div>
	);
}
