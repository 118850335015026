import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => {
	const isReelCard = props?.activeTab === "reels" || props?.cardType === "reel";

	return {
		container: {
			borderRadius: "1.5rem"
		},
		wrapper: {
			width        : "100%",
			aspectRatio  : "9/16",
			overflow     : "hidden",
			borderRadius : props?.isMobile ? 0 : ( props?.cardType === "reel" ? props?.activeTab === "reels" ? "0" : "1rem" : "1rem" ),
			position     : "relative",
			cursor       : "pointer"
		},
		imageContainer: {
			position     : "relative",
			display      : "flex",
			cursor       : "pointer",
			// background   : props?.isAnimationStarted ? theme.other.paletteNew.darkModeInclinedBlack1 : "inherit",
			borderRadius : props?.cardType === "reel" ? props?.activeTab === "reels" ? "0" : "1rem" : "0",
			cover        : "contain",
			aspectRatio  : isReelCard ? "9/16" : "16/9",

			outline  : props?.cardType == "reel" ? props?.activeTab == "reels" ? "none" : `1px solid ${ theme.other.colors.border.primary }` : "none",
			overflow : "hidden"
		},
		zoomedContainer: {
			background              : theme.other.colors.background.tertiary,
			width                   : "100%",
			position                : "absolute",
			borderBottomRightRadius : "1.5rem",
			borderBottomLeftRadius  : "1.5rem",
			transition              : "0.1s",
			boxShadow               : theme.other.colors.boxShadow.primary
		},
		animationDiv: {
			borderRadius : "1.5rem",
			position     : "relative"

			/* "&:hover": {
        ...( props?.isAnimationStarted && {
          transform : `translate(${ props?.xPosition || 0 }px, 0px) scale(1.2)`,
          boxShadow : theme.other.darkMode ? "unset" : theme.other.colors.boxShadow.primary
        } ),
        zIndex: 20
      } */

		},
		imgContainer: {
			aspectRatio: isReelCard ? "9/16" : "16/9",

			"& .hoveringTextContainer": {
				display      : "none",
				position     : "absolute",
				bottom       : isReelCard ? "1%" : "4%",
				right        : isReelCard ? "2%" : "2%",
				background   : theme.other.paletteNew.secondaryBlack,
				padding      : "0.5rem 1rem",
				borderRadius : "0.75rem",

				"& .hoveringText": {
					fontWeight : "500",
					fontSize   : "1.25rem",
					color      : theme.other.paletteNew.darkModeWhite80
				}
			},

			"&:hover": {
				"& .hoveringTextContainer": {
					display: "block"
				}
			}

		},
		image: {
			borderRadius : "1rem",
			aspectRatio  : "inherit",
			height       : "100%",
			objectFit    : "cover",
			position     : "absolute",
			top          : "0%",

			[rooterMediaQuery.mobile]: {
				borderRadius: props?.activeTab !== "reels" && props?.cardType == "reel" ? "12px" : "0px"
			}
		},
		playerContainer: {
			height       : "100%",
			width        : "100%",
			background   : theme.other.colors.background.tertiary,
			borderRadius : "1rem",
			overflow     : "hidden"
		},

		divider: {
			height     : "5px",
			background : theme.other.colors.border.primary
		},
		customControls: {

		},
		muteIcon: {
			width        : "2.5rem",
			height       : "2.5rem",
			display      : "block",
			background   : theme.other.paletteNew.secondaryBlack50,
			borderRadius : "100%",
			cursor       : "pointer",
			position     : "absolute",
			top          : "3%",
			right        : "3%"
		},
		overlayRoot: {
			display        : "flex",
			textAlign      : "center",
			flexDirection  : "column",
			height         : "100%",
			alignItems     : "center",
			justifyContent : "center",
			cursor         : "default"
		},
		overlayInner: {
			width          : "60%",
			display        : "flex",
			flexDirection  : "column",
			alignItems     : "center",
			justifyContent : "center"
		},
		iconWrapper: {
			marginBottom: "1rem",

			"&[data-clicked]": {
				animation: "rotation 0.5s linear infinite"
			},

			"@keyframes rotation": {
				"0%": {
					transform: "rotate(0deg)"
				},
				"100%": {
					transform: "rotate(360deg)"
				}
			}
		},
		overlayIcon: {
			width  : "3rem",
			height : "3rem",
			cursor : "pointer"
		},
		overlayTitle: {
			fontSize      : "1.4rem",
			fontWeight    : "500",
			textTransform : "capitalize",
			color         : theme.other.paletteNew.darkModeWhite80,
			animation     : "pulse 2s ease-in-out infinite",

			"@keyframes pulse": {
				"0%": {
					opacity: "0.5"
				},
				"50%": {
					opacity: "1"
				},
				"100%": {
					opacity: "0.5"
				}
			}
		},
		overlaySubtitle: {
			fontSize  : "1.4rem",
			textAlign : "center"
		}

	};
} );
