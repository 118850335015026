import { Transition } from "@mantine/core";
import dayjs          from "dayjs";
import fromNow        from "dayjs/plugin/relativeTime";
import utc            from "dayjs/plugin/utc";
import dynamic        from "next/dynamic";
import { useRouter }  from "next/router";
import { useRef }     from "react";

import { useGetProStatus, useGetProfileData } from "_common/hooks/auth";
import { useGetDeviceType }                   from "_common/hooks/global";
import {
	useGetCardMuteState,
	useSetCardMuteState
} from "_common/hooks/player";
import { isPageRoute }               from "_common/utils";
import Icon                          from "_components/atoms/icon";
import Image                         from "_components/atoms/image";
import Render                        from "_components/atoms/render";
import Typography                    from "_components/atoms/typography";
import ProBar                        from "_components/features/player/proBar";
import { STREAM_TIER }               from "_components/pages/proPage/constants";
import { useGetCanWatchMoreContent } from "_jotai/compulsoryLogin";
import {
	useGetAdWatchStatus,
	useSetAdWatchStatus
} from "_jotai/rooterPro";

import useStyles from "./styles";
import Tags      from "./tags";

const VideoPlayer = dynamic ( () => import ( "_components/features/player" ) );

dayjs.extend ( utc );
dayjs.extend ( fromNow );

const component = ( {
	data,
	leftMostCard,
	rightMostCard,
	watchNowClickHandler,
	noAnimation,
	isAnimationStarted,
	activeTab,
	cardType,
	isProStream
} ) => {

	const divRef           = useRef ();
	const deviceType       = useGetDeviceType ();
	const muted            = useGetCardMuteState ();
	const toggleMute       = useSetCardMuteState ();
	const setAdWatchStatus = useSetAdWatchStatus ();

	const xPosition = leftMostCard ? ( divRef?.current?.offsetWidth / 10 ) : rightMostCard ? -( divRef?.current?.offsetWidth / 10 ) : 0;

	const { classes } = useStyles ( {
		isAnimationStarted,
		xPosition,
		activeTab,
		cardType,
		isMobile : deviceType === "mobile" ? true : false,
		feedType : data.feedType
	}, { name: "videoCard" } );

	noAnimation = deviceType !== "desktop" || noAnimation;

	const customControls = () => (
		<CustomControls
			data               = { data }
			isAnimationStarted = { isAnimationStarted }
			muted              = { muted }
			toggleMute         = { toggleMute }
		/>
	);

	const thumbnailAnimation = {
		in                 : { opacity: 1 },
		transitionProperty : "opacity",
		out                : { opacity: 0.5 }
	};

	const videoAnimation = {
		in                 : { opacity: 1 },
		transitionProperty : "opacity",
		out                : { opacity: 0.5 }
	};

	const router        = useRouter ();
	const userProfile   = useGetProfileData ();
	const isOwnStream   = userProfile?.id === data?.userData?.id;
	const isProfilePage = isPageRoute ( { router, page: "profile" } );
	const isProUser     = useGetProStatus ();

	const showProBar = () => {
		if ( data?.streamTier?.toString () === STREAM_TIER.MID_STREAM ) {
			if ( isProUser )
				return false;

			return !!data?.midTierRemainingAdWatchCount;
		}

		if ( isProfilePage ) {
			return !isOwnStream;
		}

		return true;
	};

	return (
		<div className = { classes.wrapper }>
			{/* <Render condition = { adWatchStatus.opened && data?.streamTier?.toString () === STREAM_TIER.MID_STREAM }>
				<RewardedAds
					onAdFinish = { onAdFinish }
				/>
			</Render> */}

			<Render condition = { showProBar () }>
				{/* show pro tag in feed items*/}
				<ProBar
					inProp      = { true }
					proExpiring = { false }
					showTimer   = { false }
					showWatchAd = { () => {
						setAdWatchStatus ( p => ( {
							...p,
							opened: true
						} ) );
						watchNowClickHandler ( { currentTime: 0 } );
					} }
					streamId    = { data?.id }
					streamTier  = { data?.streamTier?.toString () }
				/>
			</Render>

			<Transition
				duration       = { 100 }
				keepMounted    = { false }
				mounted        = { isAnimationStarted }
				timingFunction = "ease"
				transition     = { videoAnimation }
			>
				{styles => (
					<div style = { { ...styles, height: "100%" } }>
						<VideoContainer
							customControls       = { customControls }
							data                 = { data }
							muted                = { muted }
							watchNowClickHandler = { watchNowClickHandler }
						/>

					</div>
				)}
			</Transition>

			<Transition
				duration       = { 80 }
				keepMounted    = { false }
				mounted        = { !isAnimationStarted }
				timingFunction = "ease"
				transition     = { thumbnailAnimation }
			>
				{styles => (
					<div style = { { ...styles, height: "100%" } }>
						<ThumbnailContainer
							activeTab            = { activeTab }
							cardType             = { cardType }
							data                 = { data }
							isProStream          = { isProStream }
							noAnimation          = { noAnimation }
							watchNowClickHandler = { watchNowClickHandler }
						/>
					</div>
				)}
			</Transition>
		</div>
	);
};

const CustomControls = ( { toggleMute, isAnimationStarted, data, muted } ) => {
	const { classes } = useStyles ( undefined, { name: "customControls" } );

	if ( !isAnimationStarted ) return null;

	return (
		<div className = { classes.customControls }>
			<Icon
				sameInBothTheme
				className = { classes.muteIcon }
				name      = { muted ? "volumeOff" : "volumeOn" }
				onClick   = { e => {
					e.stopPropagation ();
					toggleMute ( prevState => !prevState );
				} }
			/>

			<Tags data = { data } />
		</div>
	);
};

export default component;

function VideoContainer ( { customControls, watchNowClickHandler, data, muted } ) {
	const { classes } = useStyles ( undefined, { name: "videoContainer" } );

	return (
		<div className = { classes.playerContainer }>
			<VideoPlayer
				disableControlSettings
				disableTheatreMode
				fromVideoCard
				noControls
				customControls     = { customControls }
				isLive             = { true }
				liveViews          = { data.views }
				muted              = { muted }
				onClick            = { ( { currentTime } ) => watchNowClickHandler ( { currentTime } ) }
				overridePlayerMute = { true }
				src                = { data.src }
				thumbnail          = { data.thumbnail }
				thumbnailType      = "hdpi"
			/>
		</div>
	);
}

function ThumbnailContainer ( { watchNowClickHandler, data, noAnimation, cardType, activeTab, isProStream } ) {

	const { classes }         = useStyles ( { activeTab, cardType } );
	const canWatchMoreContent = useGetCanWatchMoreContent ();
	const isProUser           = useGetProStatus ();
	const isWatchAdStream     = data.streamTier?.toString () === STREAM_TIER.MID_STREAM;

	return (
		<div className = { classes.imgContainer }
			onClick       = { watchNowClickHandler }
		>
			<Image
				className   = { classes.image }
				placeholder = "/images/rectangle-placeholder.webp"
				src         = { data.thumbnail }
				type        = "hdpi"
			/>

			<Tags data = { data } />

			<Render condition = { !noAnimation && !isProStream && !( isProUser && isWatchAdStream ) }>
				{!canWatchMoreContent ? (
					<div className = { "hoveringTextContainer" }>
						<Typography className = "hoveringText"
							title                = "Login To Play"
						/>
					</div>
				) : isWatchAdStream ? (
					<div className = { "hoveringTextContainer" }>
						<Typography className = "hoveringText"
							title                = "Watch ad to continue"
						/>
					</div>
				) : (
					<div className = { "hoveringTextContainer" }>
						<Typography className = "hoveringText"
							title                = "Keep hovering to play"
						/>
					</div>
				)}
			</Render>
		</div>
	);
}
