import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => {

	return {
		watchNow: {
			margin       : "1rem 0",
			padding      : "0.675rem 1rem !important",
			marginTop    : "1.5rem",
			marginBottom : "0.75rem"
		},
		contentContainer: {
			margin: "1rem 0"
		},
		titleContainer: {
			display        : "flex",
			justifyContent : "space-between",

			"& .threeDotIcon": {
				visibility: props?.isThreeDotMenuOpen ? "visible" : "hidden"
			},

			"&:hover": {
				"& .threeDotIcon": {
					visibility: "visible"
				}

			},

			[rooterMediaQuery.tablet]: {
				"& .threeDotIcon": {
					visibility: "visible !important"

				}
			}
		},
		title: {
			fontWeight      : "500",
			// fontSize        : "1.6rem",
			display         : "-webkit-box",
			WebkitLineClamp : "2",
			WebkitBoxOrient : "vertical",
			overflow        : "hidden",
			width           : "87.5%",
			wordBreak       : "break-word",
			cursor          : "pointer"
		},
		name: {
			// fontSize        : "1.25rem",
			// marginLeft      : "1rem",
			// whiteSpace   : "nowrap",
			// overflow     : "hidden",
			// textOverflow : "ellipsis",
			maxWidth        : "85%",
			WebkitBoxOrient : "vertical",
			WebkitLineClamp : "1"
		},
		ellipse: {
			width           : "4px",
			height          : "4px",
			margin          : "0rem 0.7rem",
			borderRadius    : "50%",
			backgroundColor : "#757575"
		},
		streamInfo: {
			display    : "flex",
			alignItems : "center"

			// "& p": {
			// fontSize: "1.25rem"
			// }
		},
		verifyIcon: {
			height     : "1.5rem",
			width      : "1.5rem",
			marginLeft : "0.25rem"
		},
		nameContainer: {
			display    : "flex",
			alignItems : "center",
			width      : "100%"
			// marginBottom : "0.25rem"
		},
		profileImage: {
			height       : "3.5rem",
			width        : "3.5rem",
			borderRadius : "100%",
			marginRight  : "1rem"
		},
		detailsContainer: {
			width: "100%"
		},
		watchNowText: {
			fontSize: "1.25rem"
		},
		imageLink: {
			width: "15%"
		}
	};
} );
