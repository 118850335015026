import Typography from "_components/atoms/typography";

import useStyles from "./styles";

export default function LiveTag ( { containerClass = "", iconClass = "", textClass = "" } ) {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	return (
		<div className = { `${ classes.container } ${ containerClass }` }>

			{/* <Icon sameInBothTheme
				className = { `${ classes.icon } ${ iconClass }` }
				name = "gift"
			>
			</Icon> */}

			<Typography className = { `${ classes.text } ${ textClass } uppercase` }
				title                = { "live" }
			/>
		</div>
	);
}
