import XHR         from "_common/xhr";
import { configs } from "_config/index";

/*
  Dev note: https://docs.google.com/document/d/1we6s6KNl94SdOI0b8stHoyQYk31QdSHZp36LHEUQqcQ/edit

  Please refer to this doc and postman collection

*/

export const sendBroadcastWatchEngagement = async params => {
	const {
		liveSessionId,
		viewStart,
		relativeTime,
		watchStart,
		watchEnd,
		isLive,
		isAutoplay,
		webVisibility
	} = params;

	const response = await XHR.post ( {
		url  : `${ configs.ENGAGEMENT }clickstream/ls-watch`,
		data : {
			watch: [
				{
					liveSessionId: parseInt ( liveSessionId ),
					viewStart,
					relativeTime,
					watchStart,
					watchEnd,
					isLive,
					isAutoplay,
					webVisibility
				}
			]
		}
	} );

	return response;
};

export const sendBroadcastReactions = async ( { broadcastSessionId, reactionCount, reactionId } ) => {

	const reactionData = {
		liveSessionId : parseInt ( broadcastSessionId ),
		reactions     : [
			{
				reactionId,
				reactionCount
			}
		],
		isLive: true // hardcoded true because android decided to send reactions for non live

	};

	const res = await XHR.post ( {
		url               : `${ configs.ENGAGEMENT }ls-reaction`,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		headers: {
			"Content-Type": "application/json"
		},
		data: reactionData
	} );

	return res;
};

export const addCommentOnBroadcast = async data => {

	const { broadcastId, commentText, requestId } = data;

	let commentData = {
		liveSessionId : parseInt ( broadcastId ),
		fromClient    : true,
		commentText,
		requestId
	};

	const response = await XHR.post.bind ( this ) ( {
		url               : `${ configs.ENGAGEMENT }ls-comments`,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		headers: {
			"Content-Type": "application/json"
		},
		data: commentData
	} );

	return response;
};

export const sendBroadcastClickStreamEngagement = async params => {
	const {
		liveSessionId,
		isLive
	} = params;

	const response = await XHR.post ( {
		url  : `${ configs.ENGAGEMENT }clickstream/ls-views`,
		data : {
			views: [
				{
					liveSessionId: parseInt ( liveSessionId ),
					isLive
				}
			]
		}
	} );

	return response;
};

export const sendGAMTrackerEvent = async data => {
	const response = await XHR.post ( {
		url: `${ configs.ENGAGEMENT }ads/gam-ads`,
		data
	}, {
		noHeaders     : true,
		emptyResponse : true
	} );

	return response;
};
