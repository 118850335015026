import { createStyles } from "@mantine/emotion";

export default createStyles ( () => ( {
	viewTagContainer: {
		background   : "rgb(0,0,0,0.5)",
		padding      : "0.4rem 0.8rem",
		borderRadius : "3px",
		display      : "flex",
		alignItems   : "center"
	},
	viewTagText: {
		fontWeight : "500",
		color      : "#fff !important",
		marginLeft : "0.3rem",
		fontSize   : "1.2rem"
	},
	reelPlayeIcon: {
		width  : "16px",
		height : "15px"
	}
} ) );