import Presentation from "./presentation";

const BottomDetailsSection = ( {
	data,
	activeTab,
	onClickMenuOption,
	isAnimationStarted,
	isLoggedIn,
	watchNowClickHandler,
	hideMenuOptions,
	getShareLink,
	customClasses,
	cardType
} ) => {

	return (
		<Presentation
			activeTab            = { activeTab }
			cardType             = { cardType }
			customClasses        = { customClasses }
			data                 = { data }
			getShareLink         = { getShareLink }
			hideMenuOptions      = { hideMenuOptions }
			isAnimationStarted   = { isAnimationStarted }
			isLoggedIn           = { isLoggedIn }
			onClickMenuOption    = { onClickMenuOption }
			watchNowClickHandler = { watchNowClickHandler }

		/>
	);
};

export default BottomDetailsSection;