import { useRouter } from "next/router";
import { useState }  from "react";

import { useGetLoggedIn }         from "_common/hooks/auth";
import { useSetLoginPopup }       from "_common/hooks/global";
import { sendBroadcastReactions } from "_services/api/engagement";
import { postLikeOnFeed }         from "_services/api/feed";

import Presentation from "./presentation";

const MobileBottomDetailsSection = props => {
	const [ isReacted, setIsReacted ]                     = useState ( false );
	const [ isOpenCommentDrawer, setIsOpenCommentDrawer ] = useState ( false );

	const router = useRouter ();

	const isLoggedIn    = useGetLoggedIn ();
	const setLoginPopup = useSetLoginPopup ();

	const onClickLike = async () => {
		if ( !isLoggedIn ) {
			return setLoginPopup ( true );
		}

		if ( isReacted )
			return;

		try {
			if ( props?.data.feedType === "LIVESESSION" ) {
				const data = {
					broadcastSessionId : props?.data.id,
					reactionCount      : 1,
					reactionId         : 1 // Like
				};

				await sendBroadcastReactions ( data );
				setIsReacted ( true );
			} else {
				await postLikeOnFeed ( { feedId: props?.data.id } );
				setIsReacted ( true );
			}
		} catch ( error ) {
			// api error
		}

	};

	const isLive =  props?.data?.feedType === "LIVESESSION";

	const onClickCommentIcon = () => {
		if ( isLive ) {
			router.push ( `/stream/${ props?.data?.id }` );
		} else {
			setIsOpenCommentDrawer ( true );
		}
	};

	return (
		<Presentation
			{ ...props }
			isLive               = { isLive }
			isOpenCommentDrawer  = { isOpenCommentDrawer }
			isReacted            = { isReacted }
			onClickCommentIcon   = { onClickCommentIcon }
			onClickLike          = { onClickLike }
			onCloseCommentDrawer = { () => setIsOpenCommentDrawer ( false ) }
		/>
	);
};

export default MobileBottomDetailsSection;