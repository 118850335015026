import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => ( {
	container: {
		// backgroundImage : "linear-gradient(to bottom, #ff177e 0%, #ff6a38 100%)",
		backgroundColor : theme.other.paletteNew.tertiaryPink,
		padding         : "0.2rem 0.8rem",
		borderRadius    : "3px",
		display         : "flex",
		alignItems      : "center"
	},
	text: {
		fontWeight : "500",
		color      : "#fff !important",
		// marginLeft : "0.3rem",
		fontSize   : "1.2rem"
	},
	icon: {
		height : "1rem",
		width  : "1rem"
	}
} ) );